html {
  background-color: rgb(74, 197, 231);
  height: 100vh;
}

html:has(.game-menu) {
  background-color: none;
  background-image: url('../public/machi_bg_upd.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.Die-shaking {
  animation: wobble 1s ease;
}

/* Setting Animation effect to the  
   dice using css keyframe */
@keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }

  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
